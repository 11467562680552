<template>
  <v-container class="white py-0 px-0" fluid>
    <p>Please select the file from your local machine.</p>
    <p>Note: Maximum file size is 5MB.</p>
    <p />
    <v-form ref="form" v-model="params.valid">
      <v-file-input
        v-model="params.file"
        id="filerUploader"
        accept=".xls,.xlsx,.xlsm"
        :label="$t('general.fileInput')"
        :rules="rules.file"
        required
        show-size
        @change="validate"
      ></v-file-input>
    </v-form>
    <!-- @change="
        $emit('update:value', { file })
      " -->
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "UploadDialog",
  model: {
    prop: "params",
    event: "input",
  },
  props: {
    params: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      file: null,
      rules: {
        file: [
          (v) => !!v || "Please select a file",
          (v) =>
            (!!v && v.size < 5242880) ||
            "The maximum file size allowed is 5MB",
        ],
      },
    };
  },
  methods: {
    ...mapActions("ui", ["updateDialogParams"]),

    validate() {
      this.$refs.form.validate();
    },
  },
};
</script>
