<template>
  <v-container class="white py-0 px-0" fluid>
    <v-card flat>
      <v-toolbar flat dense class="grey lighten-5">
        <v-toolbar-title class="py-3 px-0">{{
          $t("route.bulkImport")
        }}</v-toolbar-title>
        <v-spacer />
        <v-chip class="px-2" small label v-if="isReadOnly" color="yellow">
          <v-avatar left>
            <v-icon small>mdi-alert</v-icon>
          </v-avatar>
          {{ $t("general.readonly") }}
        </v-chip>
      </v-toolbar>

      <v-card-text class="overflow-y-auto px-0 py-0" :style="viewHeight(102)">
        <v-list three-line subheader>
          <v-list-item dense link @click="importIncidents">
            <v-list-item-icon>
              <v-icon color="green">mdi-file-excel</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>DSIR Reports</v-list-item-title>
              <v-list-item-subtitle>
                Use this tool to upload a set of DSIR reports using the Excel
                template attached.
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-progress-circular
                :size="50"
                v-if="inprogress.srs"
                :indeterminate="inprogress.srs"
                color="primary"
              ></v-progress-circular>
            </v-list-item-action>
          </v-list-item>

          <v-divider></v-divider>

          <div v-if="feedback.srs">
            <v-chip
              class="ma-1"
              label
              small
              text-color="black"
              color="error lighten-5"
              >Not Saved</v-chip
            >
            <v-chip class="ma-1" label small
              ><v-icon small color="error">mdi-close-box</v-icon>: Error/Missing
              required data error</v-chip
            >
            <v-chip
              class="ma-1"
              label
              small
              text-color="black"
              color="warning lighten-5"
              >Saved with warnings</v-chip
            >
            <v-chip class="ma-1" label small
              ><v-icon small color="warning">mdi-alert</v-icon>: Error/Missing
              but not required data warning</v-chip
            >
          </div>
          <v-simple-table dense v-if="feedback.srs" height="230px" class="ma-1">
            <template v-slot:default>
              <thead class="grey lighten-5">
                <tr>
                  <th>Position</th>
                  <th>Incident Type</th>
                  <th>Incident Date</th>
                  <th>Narrative</th>
                  <th>Area</th>
                  <th>Oblast</th>
                  <th>Raion</th>
                  <th>Hromada</th>
                  <th>Latitude</th>
                  <th>Longitude</th>
                  <!-- <th>Settlment</th> -->
                  <!-- <th>Private Homes</th>
                  <th>Multistore Residential Building</th>
                  <th>Medical Facility</th>
                  <th>Educational Facility</th>
                  <th>Unspecified Civ Infrastructure</th>
                  <th>Industrial Premises</th>
                  <th>Private Company</th>
                  <th>Agricultural Objects</th>
                  <th>Railroad</th>
                  <th>Bridges</th>
                  <th>Water Supply</th>
                  <th>Nuclear Power Plant</th>
                  <th>Electric Plant/Station</th>
                  <th>Power Lines</th>
                  <th>Dams</th>
                  <th>Gas Lines</th>
                  <th>Ammunition Depot</th>
                  <th>Military Base</th>
                  <th>Civil Fatalities</th>
                  <th>Civil Injuries</th>
                  <th>Pol SESU Fatalities</th>
                  <th>Pol SESU Injured</th> -->
                  <th>Source</th>
                  <th>Reported By</th>
                  <th>Shift Sign</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  :class="`${
                    f.attributes['Saved'] ? 'warning' : 'error'
                  } lighten-5`"
                  v-for="(f, i) in feedback.srs"
                  :key="i"
                >
                  <td>{{ f.resourceId }}</td>
                  <td><v-icon small :color="getColor(f, 'Incident Type')">{{ getIcon(f, "Incident Type") }}</v-icon></td>
                  <td><v-icon small :color="getColor(f, 'Incident Date')">{{ getIcon(f, "Incident Date") }}</v-icon></td>
                  <td><v-icon small :color="getColor(f, 'Narrative')">{{ getIcon(f, "Narrative") }}</v-icon></td>
                  <td><v-icon small :color="getColor(f, 'Area')">{{ getIcon(f, "Area") }}</v-icon></td>
                  <td><v-icon small :color="getColor(f, 'Oblast')">{{ getIcon(f, "Oblast") }}</v-icon></td>
                  <td><v-icon small :color="getColor(f, 'Raion')">{{ getIcon(f, "Raion") }}</v-icon></td>
                  <td><v-icon small :color="getColor(f, 'Hromada')">{{ getIcon(f, "Hromada") }}</v-icon></td>
                  <td><v-icon small :color="getColor(f, 'Latitude')">{{ getIcon(f, "Latitude") }}</v-icon></td>
                  <td><v-icon small :color="getColor(f, 'Longitude')">{{ getIcon(f, "Longitude") }}</v-icon></td>
                  <!-- <td><v-icon small :color="getColor(f, 'Settlment')">{{ getIcon(f, "Settlment") }}</v-icon></td> -->
                  <!-- <td><v-icon small :color="getColor(f, 'Private Homes')">{{ getIcon(f, "Private Homes") }}</v-icon></td>
                  <td><v-icon small :color="getColor(f, 'Multistore Residential Building')">{{ getIcon(f, "Multistore Residential Building") }}</v-icon></td>
                  <td><v-icon small :color="getColor(f, 'Medical Facility')">{{ getIcon(f, "Medical Facility") }}</v-icon></td>
                  <td><v-icon small :color="getColor(f, 'Educational Facility')">{{ getIcon(f, "Educational Facility") }}</v-icon></td>
                  <td><v-icon small :color="getColor(f, 'Unspecified Civ Infrastructure')">{{ getIcon(f, "Unspecified Civ Infrastructure") }}</v-icon></td>
                  <td><v-icon small :color="getColor(f, 'Industrial Premises')">{{ getIcon(f, "Industrial Premises") }}</v-icon></td>
                  <td><v-icon small :color="getColor(f, 'Private Company')">{{ getIcon(f, "Private Company") }}</v-icon></td>
                  <td><v-icon small :color="getColor(f, 'Agricultural Objects')">{{ getIcon(f, "Agricultural Objects") }}</v-icon></td>
                  <td><v-icon small :color="getColor(f, 'Railroad')">{{ getIcon(f, "Railroad") }}</v-icon></td>
                  <td><v-icon small :color="getColor(f, 'Bridges')">{{ getIcon(f, "Bridges") }}</v-icon></td>
                  <td><v-icon small :color="getColor(f, 'Water Supply')">{{ getIcon(f, "Water Supply") }}</v-icon></td>
                  <td><v-icon small :color="getColor(f, 'Nuclear Power Plant')">{{ getIcon(f, "Nuclear Power Plant") }}</v-icon></td>
                  <td><v-icon small :color="getColor(f, 'Electric Plant/Station')">{{ getIcon(f, "Electric Plant/Station") }}</v-icon></td>
                  <td><v-icon small :color="getColor(f, 'Power Lines')">{{ getIcon(f, "Power Lines") }}</v-icon></td>
                  <td><v-icon small :color="getColor(f, 'Dams')">{{ getIcon(f, "Dams") }}</v-icon></td>
                  <td><v-icon small :color="getColor(f, 'Gas Lines')">{{ getIcon(f, "Gas Lines") }}</v-icon></td>
                  <td><v-icon small :color="getColor(f, 'Ammunition Depot')">{{ getIcon(f, "Ammunition Depot") }}</v-icon></td>
                  <td><v-icon small :color="getColor(f, 'Military Base')">{{ getIcon(f, "Military Base") }}</v-icon></td>
                  <td><v-icon small :color="getColor(f, 'Civil Fatalities')">{{ getIcon(f, "Civil Fatalities") }}</v-icon></td>
                  <td><v-icon small :color="getColor(f, 'Civil Injuries')">{{ getIcon(f, "Civil Injuries") }}</v-icon></td>
                  <td><v-icon small :color="getColor(f, 'Pol SESU Fatalities')">{{ getIcon(f, "Pol SESU Fatalities") }}</v-icon></td>
                  <td><v-icon small :color="getColor(f, 'Pol SESU Injured')">{{ getIcon(f, "Pol SESU Injured") }}</v-icon></td> -->
                  <td><v-icon small :color="getColor(f, 'Source')">{{ getIcon(f, "Source") }}</v-icon></td>
                  <td><v-icon small :color="getColor(f, 'Reported By')">{{ getIcon(f, "Reported By") }}</v-icon></td>
                  <td><v-icon small :color="getColor(f, 'Shift Sign')">{{ getIcon(f, "Shift Sign") }}</v-icon></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <v-list-item dense>
            <v-list-item-content>
              <v-list-item-subtitle>
                <v-col cols="12" md="6">
                  <v-btn
                    class="pa-1"
                    small
                    depressed
                    link
                    href="/files/dsir_bulkimport_v1.0.xlsx"
                    color="primary"
                  >
                    <v-icon small> mdi-file </v-icon>
                    DSIR Template File...
                  </v-btn>
                </v-col>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import UploadDialog from "../dialogs/UploadDialog";

export default {
  name: "BulkImport",
  components: [UploadDialog],
  data() {
    return {
      feedback: { srs: null },
      inprogress: { srs: false },
    };
  },
  methods: {
    ...mapActions("ui", ["showDialog", "closeDialog", "addNotification"]),

    getIcon(feedback, att) {
      const d = feedback.attributes[att];
      return d === "Error"
        ? "mdi-close-box"
        : d === "Warning"
        ? "mdi-alert"
        : "mdi-check-bold";
    },
    getColor(feedback, att) {
      const d = feedback.attributes[att];
      return d === "Error" ? "error" : d === "Warning" ? "warning" : "green";
    },
    importIncidents() {
      if (!this.isReadOnly) {
        this.importTo("/si/upload", "Bulk Import: SRS Reports");
      }
    },

    importTo(url, title) {
      const dialog = {
        title: title,
        component: UploadDialog,
        width: 430,
        params: {
          file: null,
          valid: false,
        },
        actions: [
          {
            text: this.$t("general.okay"),
            validate: true,
            click: ({ file, valid }) => {
              if (!valid) {
                return;
              }

              this.inprogress.srs = true;
              this.feedback.srs = null;
              url = process.env.ROOT_API + url;
              this.$upload(url, file)
                .then((response) => {
                  this.feedback.srs = response.data;
                  this.inprogress.srs = false;
                  this.addNotification({
                    text: "messages.updateCompleted",
                    color: "success",
                  });
                })
                .catch((error) => {
                  console.log(error);
                  this.feedback.srs = error.response.data;
                  this.inprogress.srs = false;
                  this.addNotification({
                    text: "messages.updateNotSuccessful",
                    color: "grey",
                  });
                });
              this.closeDialog();
            },
            color: "primary",
          },
          {
            text: this.$t("general.cancel"),
            click: () => {
              this.inprogress.srs = false;
              this.closeDialog();
            },
            color: "primary",
          },
        ],
      };

      this.showDialog(dialog);
    },
  },
};
</script>
